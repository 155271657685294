import 'core-js/es6/set';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/array';
import 'core-js/es6/symbol';
import 'core-js/es6/string';
import 'core-js/es7/string';
import 'core-js/es7/object';
import 'core-js/es7/array';

import './wdyr';

import bootstrap from '@ezugi/bootstrap';
import config from './config';

bootstrap(config);
