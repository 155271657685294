import {
  socketEpic,
  cardsEpic,
  cardsReducer,
  betsEpic,
  betsReducer,
  statisticsReducer,
  payoutsEpic,
} from './store/index';
import { resultMessageClassName, } from './components/ResultDisplay/style';
import RoundStepInfo from './components/RoundStepInfo';

const noop = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard'),
    Payouts: () => import('./components/Payouts'),
    LeftSideDrawerContent: () => import('./components/LeftSideDrawer'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    SixCardDialog: () => import('./components/SixCardDialog'),
    GameInfo: () => import('./components/GameInfo'),
    ResultDisplay: () => import('./components/ResultDisplay'),
    Statistics: () => import('./components/Statistics'),
    VideoOverlay: () => import('./components/VideoOverlay'),
    ProgressBar: noop,
  },
  store: {
    epics: [ socketEpic, cardsEpic, betsEpic, payoutsEpic, ],
    reducers: { cards: cardsReducer, bets: betsReducer, statistics: statisticsReducer, },
  },
  props: {
    GameResults: { hideWinnerList: true, },
    GameLeftSideDrawer: { style: { top: '-20px', }, },
    ResultMessage: {
      className: resultMessageClassName,
    },
    BetToolbar: {
      style: {
        zIndex: 1,
      },
      children: RoundStepInfo,
    },
  },
  gameBoardHeight: 22,
  landscapeToolbarOrientation: 'vertical',
  desktopBoardToggle: true,
});
