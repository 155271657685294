import {
  when, assoc, evolve, prop, mergeLeft,
} from 'ramda';
import {
  TWO_PAIRS,
  PAIR,
  THREE_OF_KIND,
  FOUR_OF_KIND,
  STRAIGHT,
  FLUSH,
  FULL_HOUSE,
  STRAIGHT_FLUSH,
  ROYAL_FLUSH,
} from '../../../constants/strings';
import * as BET_TYPES from '../../../constants/betTypes';

export const payoutsIndexMap = {
  1: PAIR,
  2: TWO_PAIRS,
  3: THREE_OF_KIND,
  4: STRAIGHT,
  5: FLUSH,
  6: FULL_HOUSE,
  7: FOUR_OF_KIND,
  8: STRAIGHT_FLUSH,
  9: ROYAL_FLUSH,
  10: FULL_HOUSE,
};

const { PAIR_PLUS, SIX_CARD, } = BET_TYPES;

function hasPayout(payouts, payoutOption) {
  return () => !!+payouts[payoutOption];
}

function getPayoutObj(p, payouts, payoutOption) {
  return {
    index: +p.IndexID,
    strength: +payouts[payoutOption],
    payout: `${payouts[payoutOption]}:1`,
    name: payoutsIndexMap[p.IndexID],
  };
}

export function formatPayouts(list = []) {
  return list.reduce(
    (acc, p) => {
      const payouts = JSON.parse(p.payout);

      return evolve({
        [BET_TYPES.PLAYER_A]: when(
          hasPayout(payouts, 'option1'),
          mergeLeft({
            payout: `${prop('option1', payouts)}:1`,
          })
        ),
        [BET_TYPES.PLAYER_B]: when(
          hasPayout(payouts, 'option1'),
          mergeLeft({
            payout: `${prop('option1', payouts)}:1`,
          })
        ),
        [PAIR_PLUS]: when(
          hasPayout(payouts, 'option2'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option2'))
        ),
        [SIX_CARD]: when(
          hasPayout(payouts, 'option3'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option3'))
        ),
      })(acc);
    },
    {
      [BET_TYPES.PLAYER_A]: {
        name: BET_TYPES.PLAYER_A,
        index: 0,
        strength: 1,
        payout: '0.95:1',
      },
      [BET_TYPES.PLAYER_B]: {
        name: BET_TYPES.PLAYER_B,
        index: 1,
        strength: 0,
        payout: '0.95:1',
      },
      [PAIR_PLUS]: {},
      [SIX_CARD]: {},
    }
  );
}
