import { createSelector, } from 'reselect';
import { pathOr, propOr, } from 'ramda';
import { BET_ON_TEEN_PATTI_BET_TYPES, } from '@ezugi/constants';

import { PUSH, WIN_PLAYER_A, } from '../../components/ResultDisplay/constants';

const { PLAYER_A, PLAYER_B, } = BET_ON_TEEN_PATTI_BET_TYPES;

export const gameResultsSelector = pathOr({}, [ 'game', 'gameResults', ]);

export const statisticsSelector = propOr({}, 'statistics');

export const lastWinnersSelector = createSelector(
  statisticsSelector,
  propOr([], 'lastWinners')
);

export const winningBetsSelector = createSelector(
  gameResultsSelector,
  (gameResults) => {
    const { SideBetsResult = [], WinningHand, } = gameResults;

    const bets = SideBetsResult.reduce((acc, curr) => {
      if (curr.HasSideBetWon) {
        return [ ...acc, curr.betType, ];
      }

      return acc;
    }, WinningHand !== PUSH ? [ WinningHand === WIN_PLAYER_A ? PLAYER_A : PLAYER_B, ] : []);

    return bets;
  }
);
