import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { cardsSelector, } from '../../selectors/cards';

export default function handleCancelLastCard(socketMessage, state) {
  const { CardHand, } = socketMessage;

  const { cards, } = cardsSelector(state)[CardHand];
  const nextCards = [ ...cards, ];
  nextCards.pop();

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: {
          cards: nextCards,
        },
      })
    )
  );
}
