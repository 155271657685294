import { createSelector, } from 'reselect';
import { prop, } from 'ramda';
import { PLAYER_A_CARD_HAND, PLAYER_B_CARD_HAND, } from '../../constants/cards';

export const cardsSelector = prop('cards');

export const playerACardsSelector = createSelector(
  cardsSelector,
  prop(PLAYER_A_CARD_HAND)
);

export const playerBCardsSelector = createSelector(
  cardsSelector,
  prop(PLAYER_B_CARD_HAND)
);

export const numberOfCardsSelector = createSelector(
  playerACardsSelector,
  playerBCardsSelector,
  (playerACards, playerBCards) => playerACards.cards.length + playerBCards.cards.length
);
