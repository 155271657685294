import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { playerACardsSelector, playerBCardsSelector, } from '../../selectors/cards';
import { PLAYER_A_CARD_HAND, } from '../../../constants/cards';

export default function handleCardMessage(socketMessage, state) {
  const { CardHand, CardName, CardValue, } = socketMessage;

  const { cards, } = CardHand === PLAYER_A_CARD_HAND ? playerACardsSelector(state) : playerBCardsSelector(state);

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: {
          cards: [
            ...cards,
            {
              CardName,
              CardValue,
            },
          ],
        },
      })
    )
  );
}
