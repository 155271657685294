import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

import { gameResultsSelector, } from '../../selectors/game';

const {
  gameActions: { game, },
  uiActions: { progressBar, },
} = actions;

export default function handlePlaceYourBets(socketMessage, state) {
  return of(
    game.set({
      gameResults: { ...gameResultsSelector(state), wonSidebets: [], },
    }),
    progressBar.reset(),
  );
}
