import { handleActions, } from 'redux-actions';

import statisticsActions from '../../actions/statistics';

const INITIAL_STATE = {
  winPercentages: {},
  sideBetsPercentages: {},
  lastWinners: [],
};

const { statistics, } = statisticsActions;

export default handleActions(
  {
    [statistics.set]: (state, { payload, }) => ({ ...state, ...payload, }),
  },
  INITIAL_STATE
);
