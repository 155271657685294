import { createSelector, } from 'reselect';
import { prop, has, anyPass, } from 'ramda';
import { selectors, } from '@ezugi/bootstrap';

import { BET_ON_TEEN_PATTI_BET_TYPES, } from '@ezugi/constants';

const { PLAYER_A, PLAYER_B, PAIR_PLUS_A, PAIR_PLUS_B, SIX_CARD, } = BET_ON_TEEN_PATTI_BET_TYPES;

const { betsSelector, } = selectors;

export const currentBetsSelector = createSelector(
  betsSelector,
  prop('current')
);

export const betsHistorySelector = createSelector(
  betsSelector,
  prop('history')
);

export const lastBetsSelector = createSelector(
  betsSelector,
  prop('last')
);

export const hasMainBetSelector = createSelector(
  currentBetsSelector,
  anyPass([ has(PLAYER_A), has(PLAYER_B), ])
);

export const hasSideBetSelector = createSelector(
  currentBetsSelector,
  anyPass([ has(PAIR_PLUS_A), has(PAIR_PLUS_B), has(SIX_CARD), ])
);
