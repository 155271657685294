export const SIDE_BETS = 'sideBets';

export const NO_WELCOME_DIALOG = 'NO_WELCOME_DIALOG_BOTP';
export const WELCOME_DIALOG = 'WelcomeDialog';
export const SIX_CARD_DIALOG = 'SixCardDialog';

export const HIGH_CARD = 'HighCard';
export const PAIR = 'Pair';
export const THREE_OF_KIND = 'ThreeOfKind';
export const FOUR_OF_KIND = 'FourOfKind';
export const TWO_PAIRS = 'TwoPairs';
export const MINI_ROYAL = 'MiniRoyal';
export const STRAIGHT = 'Straight';
export const FLUSH = 'Flush';
export const FULL_HOUSE = 'FullHouse';
export const STRAIGHT_FLUSH = 'StraightFlush';
export const ROYAL_FLUSH = 'RoyalFlush';
